<!--
 * @Author: wxb
 * @Date: 2022-03-23 09:19:10
 * @LastEditors: wxb
 * @LastEditTime: 2022-04-21 16:52:35
 * @Description:
-->
<template>
  <div class="containner">
    <headerNav :activeIdx='9'></headerNav>
    <div class="content">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/starCompany' }">明星企业</el-breadcrumb-item>
        <el-breadcrumb-item>{{companyDetail.enterpriseName}}</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="company-item">
        <div class="company-item-logo">
          <img class="logo"
               :src="companyDetail.logo && companyDetail.logo.path ? `/api/company/fileManagementNoLogin/preview?fileName=${companyDetail.logo.name}&filePath=${companyDetail.logo.path}`: nologo" />
        </div>
        <div class="company-item-content">
          <div class="company-title">{{companyDetail.enterpriseName}}</div>
          <div class="company-contacts">
            <div class="contact-item">联系人：<span class="info">{{companyDetail.contacts}}</span></div>
            <div class="contact-item">联系方式：<span class="info">{{companyDetail.contactTelephone}}</span></div>
          </div>
          <div class="company-address">机构地址：<span class="info">{{companyDetail.diZh}}</span></div>
          <div class="company-address">主营：<span class="info">{{companyDetail.mainBusiness}}</span></div>
        </div>
      </div>
      <div class="company-nav">
        <div class="company-nav-item"
             v-for="(item,index) in navList"
             :key="index"
             :class="activeNav===index?'active':''"
             @click="handleClickNav(index)">{{item}}</div>
      </div>
      <div class="company-nav-content"
           v-show="activeNav===0">
        <index @loadMore="loadMoreProduct" />
      </div>
      <div class="company-nav-content"
           v-show="activeNav===1">
        <solution />
      </div>
      <div class="company-nav-content"
           v-show="activeNav===2">
        <product />
      </div>
    </div>
    <footerNav></footerNav>
  </div>
</template>

<script>
import headerNav from '@/components/headerNavBlue'
import footerNav from '@/components/footerNav'
import index from './module/index'
import product from './module/product'
import solution from './module/solution'
import Bus from './module/bus.js'
import { getStarEnterpriseByOne } from '@/api/startCompany/index'
import nologo from '@/assets/no-logo.png'
export default {
  name: 'starCompanyDetail',
  components: {
    headerNav,
    footerNav,
    index,
    product,
    solution
  },
  data() {
    return {
      id: '',
      companyDetail: {},
      navList: ['首页', '解决方案', '主营产品'],
      activeNav: 0,
      nologo
    }
  },
  methods: {
    handleClickNav(i) {
      this.activeNav = i
      this.$router.push({
        name: 'starCompanyDetail',
        params: { id: this.id, nav: i }
      })
    },
    loadMoreProduct() {
      this.activeNav = 2
    },
    loadCompanyInfo() {
      getStarEnterpriseByOne({ enterpriseId: this.id }).then(res => {
        if (res.ok) {
          this.companyDetail = res.data
          Bus.$emit('companyInfo', this.companyDetail)
        }
      }).catch(err => {
        console.log(err)
      })
    }
  },
  mounted() {
    const reqParams = this.$route.params
    this.id = reqParams.id
    this.activeNav = parseInt(reqParams.nav)
    this.loadCompanyInfo()
  }
}
</script>
<style lang="scss" scoped>
.content {
  width: 144rem;
  margin: 0 auto;
}

.el-breadcrumb {
  height: 5.4rem;
  line-height: 5.4rem;
}
// 企业基本信息
.company {
  &-item {
    position: relative;
    width: 100%;
    display: flex;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    &-content {
      flex: 1;
      padding-left: 10px;
    }

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    &-logo {
      width: 240px;
      background: #ffffff;
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
      display: flex;
      align-items: center;
      justify-content: center;

      .logo {
        width: auto;
        height: 88px;
      }
    }

    .info {
      color: rgba(0, 0, 0, 0.65);
    }
  }
  &-title {
    box-sizing: border-box;
    font-size: 18px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 32px;
    font-weight: 500;
  }

  &-contacts {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.45);
    line-height: 40px;
    font-weight: 400;
    display: flex;
    align-items: center;
    .contact {
      &-item {
        width: 400px;
      }
    }
  }

  &-address {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.45);
    line-height: 40px;
    font-weight: 400;
  }
}

// 导航信息
.company {
  &-nav {
    background: #fafafa;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    &-item {
      padding: 15px 0;
      width: 200px;
      text-align: center;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.85);
      line-height: 16px;
      font-weight: 400;
      cursor: pointer;

      &.active {
        color: #ff9b41;
        border-bottom: 2px solid #ff9b41;
      }
    }

    &-content {
      padding: 10px 0;
    }
  }
}
</style>
