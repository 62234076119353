<!--
 * @Author: wxb
 * @Date: 2022-03-23 11:44:08
 * @LastEditors: wxb
 * @LastEditTime: 2022-04-12 14:59:16
 * @Description:
-->
<template>
  <div class="solution-container">
    <div v-show="!noData">
      <div class="product-list">
        <div class="product-list-item"
             v-for="(item,index) in productList"
             :key="index"
             @click="handleClickProduct(item.id)">
          <div class="product-image">
            <el-image fit="contain"
                      :src="item.productMainGraph && item.productMainGraph.path ? `/api/company/fileManagementNoLogin/preview?fileName=${item.productMainGraph.name}&filePath=${item.productMainGraph.path}`: nologo"
                      class="product-img"></el-image>
          </div>
          <div class="product-name">
            {{item.productName}}
          </div>
        </div>
      </div>
      <div class="content-page">
        <el-pagination background
                       layout="prev, slot, next"
                       :total="cnt"
                       :current-page.sync="currpage"
                       :page-size="20"
                       @current-change="handleCurrentChange">
          <span class="current-page">{{currpage}}</span>
        </el-pagination>
      </div>
    </div>
    <div v-show="noData">
      <el-empty description="未查询到相关数据"></el-empty>
    </div>
  </div>
</template>

<script>
import Bus from './bus.js'
import { getStarProductList } from '@/api/startCompany/index'
import nologo from '@/assets/no-logo.png'
export default {
  data() {
    return {
      comid: '',
      productList: [],
      noData: false,
      searchForm: {
        enterpriseId: '',
        start: 0,
        limit: 20
      },
      currpage: 1,
      cnt: 0,
      nologo
    }
  },
  methods: {
    handleClickProduct(id) {
      const routeData = this.$router.resolve({
        name: 'productDetail',
        params: { proid: id, comid: this.comid }
      })
      window.open(routeData.href, '_blank')
    },
    // 处理分页
    handleCurrentChange(val) {
      this.searchForm.start = (val - 1) * this.searchForm.limit
      this.getList()
    },
    loadPage(data) {
      this.searchForm.enterpriseId = data.enterpriseId
      this.comid = data.enterpriseId
      this.getList()
    },
    getList() {
      getStarProductList(this.searchForm).then(res => {
        if (res.ok) {
          this.productList = res.lst
          this.cnt = res.cnt
          if (res.cnt > 0) {
            this.noData = false
          } else {
            this.noData = true
          }
        }
      }).catch(err => {
        console.log(err)
      })
    }
  },
  mounted() {
    Bus.$on('companyInfo', data => {
      this.loadPage(data)
    })
  }
}
</script>

<style lang="scss" scoped>
.product {
  &-list {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;

    &-item {
      box-sizing: border-box;
      width: 276px;
      height: 155px;
      margin-left: 10px;
      margin-top: 10px;
      cursor: pointer;

      &:hover {
        box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);
      }

      .product {
        &-image {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 16px 16px 0;
        }

        &-img {
          width: 244px;
          height: 98px;
          border: 1px solid #ddd;
        }

        &-name {
          text-align: center;
          font-size: 16px;
          color: rgba(0, 0, 0, 0.85);
          line-height: 40px;
          font-weight: 500;
          overflow: hidden; //超出的文本隐藏
          text-overflow: ellipsis; //溢出用省略号显示
          white-space: nowrap; //溢出不换行
        }
      }
    }
  }
}

.content-page {
  padding: 10px 0;
  text-align: right;
}
</style>
