<!--
 * @Author: wxb
 * @Date: 2022-03-23 11:43:56
 * @LastEditors: wxb
 * @LastEditTime: 2022-04-22 20:24:47
 * @Description:
-->
<template>
  <div class="content">
    <el-carousel :interval="5000"
                 arrow="always"
                 v-show="carouselList.length>0">
      <el-carousel-item v-for="item in carouselList"
                        :key="item">
        <el-image :src="item"
                  fit="cover"
                  class="carousel-img">
        </el-image>
      </el-carousel-item>
    </el-carousel>
    <div class="com-detail">
      <div class="com-detail-left">
        <div class="com-detail-left-header">
          <div class="com-detail-left-header-title">
            推荐产品
          </div>
          <div class="com-detail-left-header-tools"
               @click="handleClickMore">
            更多信息<i class="el-icon-arrow-right"></i>
          </div>
        </div>
        <div class="com-detail-left-content">
          <div class="product-list"
               v-show="!noData">
            <div class="product-list-item"
                 v-for="(item,index) in productList"
                 :key="index"
                 @click="handleClickProduct(item.starProductId,item.enterpriseId)">
              <div class="product-image">
                <el-image fit="contain"
                          :src="item.productMainGraph && item.productMainGraph.path ? `/api/company/fileManagementNoLogin/preview?fileName=${item.productMainGraph.name}&filePath=${item.productMainGraph.path}`: nologo"
                          class="product-img"></el-image>
              </div>
              <div class="product-name">
                {{item.productName}}
              </div>
            </div>
          </div>
          <el-empty description="未查询到相关数据"
                    v-show="noData"></el-empty>
        </div>
      </div>
      <div class="com-detail-right">
        <div class="com-detail-right-header">
          <div class="com-detail-right-header-title">
            公司概况
          </div>
        </div>
        <div class="com-detail-right-content">
          {{companyDetail.survey}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Bus from './bus.js'
import { getStarRecommendproductList } from '@/api/startCompany/index'
import nologo from '@/assets/no-logo.png'
export default {
  data() {
    return {
      id: '',
      carouselList: [],
      productList: [],
      companyDetail: {},
      nologo,
      noData: false
    }
  },
  methods: {
    handleClickMore() {
      console.log('加载更多')
      this.$emit('loadMore')
    },
    handleClickProduct(id, enterpriseId) {
      const routeData = this.$router.resolve({
        name: 'productDetail',
        params: { proid: id, comid: enterpriseId }
      })
      window.open(routeData.href, '_blank')
    },
    loadPage(data) {
      this.id = data.enterpriseId
      this.companyDetail = data
      const rotationChart = data.rotationChart || []
      const carouselList = []
      for (const item of rotationChart) {
        const url = `/api/company/fileManagementNoLogin/preview?fileName=${item.name}&filePath=${item.path}`
        carouselList.push(url)
      }
      this.carouselList = carouselList
      this.loadRecommendProduct()
    },
    loadRecommendProduct() {
      getStarRecommendproductList({
        enterpriseId: this.id
      }).then(res => {
        if (res.ok) {
          if (res.data.length > 0) {
            this.noData = false
          } else {
            this.noData = true
          }
          this.productList = res.data
        }
      }).catch(err => {
        console.log(err)
      })
    }
  },
  mounted() {
    Bus.$on('companyInfo', data => {
      this.loadPage(data)
    })
  }
}
</script>

<style lang="scss" scoped>
.content {
  width: 144rem;
  margin: 0 auto;
}

.carousel-img {
  width: 144rem;
  height: 366px;
}

/deep/ .el-carousel__container {
  height: 366px;
}

.com-detail {
  display: flex;
  margin-top: 20px;
  &-left {
    flex: 1;

    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &-title {
        padding-left: 10px;
        font-size: 18px;
        color: rgba(0, 0, 0, 0.85);
        line-height: 20px;
        font-weight: 500;
        border-left: 6px solid #ff9b41;
        cursor: default;
      }

      &-tools {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.65);
        line-height: 14px;
        font-weight: 400;
        cursor: pointer;
        padding-right: 28px;
      }
    }

    &-content {
      .product {
        &-list {
          display: flex;
          align-items: center;
          flex-direction: row;
          flex-wrap: wrap;

          &-item {
            box-sizing: border-box;
            width: 276px;
            height: 155px;
            margin-left: 10px;
            margin-top: 10px;
            cursor: pointer;

            &:hover {
              box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);
            }

            .product {
              &-image {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 16px 16px 0;
              }

              &-img {
                width: 244px;
                height: 98px;
                border: 1px solid #ddd;
              }

              &-name {
                text-align: center;
                font-size: 16px;
                color: rgba(0, 0, 0, 0.85);
                line-height: 40px;
                font-weight: 500;
                overflow: hidden; //超出的文本隐藏
                text-overflow: ellipsis; //溢出用省略号显示
                white-space: nowrap; //溢出不换行
              }
            }
          }
        }
      }
    }
  }

  &-right {
    width: 280px;
    margin-left: 0px;
    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 15px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);

      &-title {
        padding-left: 10px;
        font-size: 18px;
        color: rgba(0, 0, 0, 0.85);
        line-height: 20px;
        font-weight: 500;
        border-left: 6px solid #ff9b41;
        cursor: default;
      }
    }

    &-content {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.85);
      line-height: 30px;
      font-weight: 400;
      white-space: pre-line;
    }
  }
}
</style>
