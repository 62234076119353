<!--
 * @Author: wxb
 * @Date: 2022-03-23 11:44:04
 * @LastEditors: wxb
 * @LastEditTime: 2022-04-12 14:53:15
 * @Description:
-->
<template>
  <div class="solution-container">
    <div v-show="!noData">
      <div class="solution-list">
        <div class="solution-list-item"
             @click="handleClickSolution(item.id)"
             v-for="(item,index) in solutionList"
             :key="index">
          <div class="solution-bg">
            <el-image fit="cover"
                      :src="`/api/company/fileManagementNoLogin/preview?fileName=1.png&filePath=${item.backgroundMapFilePath}`"
                      class="img"></el-image>
          </div>
          <div class="solution-info">
            <div class="solution-info-title">{{item.programme}}</div>
            <div class="solution-info-desc">
              <div class="solution-info-desc-value">{{item.programDescribe}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="content-page">
        <el-pagination background
                       layout="prev, slot, next"
                       :total="cnt"
                       :current-page.sync="currpage"
                       :page-size="12"
                       @current-change="handleCurrentChange">
          <span class="current-page">{{currpage}}</span>
        </el-pagination>
      </div>
    </div>
    <div v-show="noData">
      <el-empty description="未查询到相关数据"></el-empty>
    </div>
  </div>
</template>

<script>
import Bus from './bus.js'
import { getStarSolutionList } from '@/api/startCompany/index'
export default {
  data() {
    return {
      comid: '',
      searchForm: {
        enterpriseId: '',
        start: 0,
        limit: 12
      },
      solutionList: [],
      noData: false,
      currpage: 1,
      cnt: 0
    }
  },
  methods: {
    handleClickSolution(id) {
      const routeData = this.$router.resolve({
        name: 'solutionDetaill',
        params: { proid: id, comid: this.comid }
      })
      window.open(routeData.href, '_blank')
    },
    // 处理分页
    handleCurrentChange(val) {
      this.searchForm.start = (val - 1) * this.searchForm.limit
      this.getList()
    },
    loadPage(data) {
      this.searchForm.enterpriseId = data.enterpriseId
      this.comid = data.enterpriseId
      this.getList()
    },
    getList() {
      getStarSolutionList(this.searchForm).then(res => {
        if (res.ok) {
          this.solutionList = res.lst
          this.cnt = res.cnt
          if (res.cnt > 0) {
            this.noData = false
          } else {
            this.noData = true
          }
        }
      }).catch(err => {
        console.log(err)
      })
    }
  },
  mounted() {
    Bus.$on('companyInfo', data => {
      this.loadPage(data)
    })
  }
}
</script>

<style lang="scss" scoped>
.solution {
  &-list {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;

    &-item {
      box-sizing: border-box;
      width: 470px;
      height: 417px;
      margin-left: 10px;
      margin-top: 10px;
      cursor: pointer;
      position: relative;

      &:hover {
        .solution-info-desc {
          display: block;
        }
      }
    }
  }

  &-bg {
    width: 100%;
    height: 417px;
    .img {
      width: 100%;
      height: 417px;
    }
  }

  &-info {
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    width: 100%;
    bottom: 0px;

    &-title {
      padding: 10px;
      font-size: 24px;
      line-height: 1.25;
      position: relative;
      z-index: 2;
      overflow: hidden;
      height: 90px;
      transition: 0.4s;
    }

    &-desc {
      display: none;
      padding: 0 10px;
      font-size: 16px;
      line-height: 22px;
      &-value {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
    }
  }
}

.content-page {
  padding: 10px 0;
  text-align: right;
}
</style>
